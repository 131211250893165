import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
const TOKEN_KEY = 'my-token';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public name ="";
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'الرئيسية',
      url: 'dashboard',
      icon: 'home'
    },

    {
      title: 'حاسبة',
      url: 'calculater',
      icon: 'calculator'
    }
    ,

    {
      title: 'الامتحانات',
      url: 'exam',
      icon: 'book'
    }
    ,

    {
      title: 'النتائج',
      url: 'result',
      icon: 'analytics'
    }
    ,

    {
      title: 'البرنامج',
      url: 'roten',
      icon: 'grid'
    }
    ,

    {
      title: 'الوظائف',
      url: 'homwork',
      icon: 'document'
    }
    ,

    {
      title: 'الملاحظات',
      url: 'note',
      icon: 'clipboard'
    }
    ,

    {
      title: 'مراسلة الادارة',
      url: 'msg',
      icon: 'call'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(   
    private authService: AuthenticationService,
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    document.documentElement.setAttribute('dir', 'rtl');
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.loadToken() ;
  }


  public async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY }); 
    //const items = JSON.stringify(token);
    const  allData = JSON.parse(token.value);
    console.log (allData.name );
    this.name = allData.name;
  }
  ngOnInit() {

  }

  ionViewWillEnter() {

   console.log ( 'ionViewWillEnter');
  }


  goToEditProgile() {
    this.router.navigateByUrl('/profile', { replaceUrl: true });
    }
    
    async logout() {
      await this.authService.logout();
      this.router.navigateByUrl('/', { replaceUrl: true });
    }

}
