import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { IntroGuard } from './guards/intro.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
 
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },{
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },{
    path: 'exam',
    loadChildren: () => import('./pages/exam/exam.module').then( m => m.ExamPageModule)
  },{
    path: 'exam',
    loadChildren: () => import('./pages/exam/exam.module').then( m => m.ExamPageModule)
  },
  {
    path: 'exam2',
    loadChildren: () => import('./pages/exam2/exam2.module').then( m => m.Exam2PageModule)
  },
  {
    path: 'result',
    loadChildren: () => import('./pages/result/result.module').then( m => m.ResultPageModule)
  },
  {
    path: 'techer',
    loadChildren: () => import('./pages/techer/techer.module').then( m => m.TecherPageModule)
  },
  {
    path: 'roten',
    loadChildren: () => import('./pages/roten/roten.module').then( m => m.RotenPageModule)
  },
  {
    path: 'holyday',
    loadChildren: () => import('./pages/holyday/holyday.module').then( m => m.HolydayPageModule)
  },
  {
    path: 'homwork',
    loadChildren: () => import('./pages/homwork/homwork.module').then( m => m.HomworkPageModule)
  },
  {
    path: 'note',
    loadChildren: () => import('./pages/note/note.module').then( m => m.NotePageModule)
  },
  {
    path: 'calculater',
    loadChildren: () => import('./pages/calculater/calculater.module').then( m => m.CalculaterPageModule)
  }, {
    path: 'msg',
    loadChildren: () => import('./pages/msg/msg.module').then( m => m.MsgPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule),
    canLoad: [AuthGuard] 
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
