import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
const TOKEN_KEY = 'my-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
// Init with null to filter out the first value in a guard!
isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
token = '';
profile ; 

public examId ;
public examName ;
public examDate;

public myGlobalUrl = 'https://shams-school.com/shams/index.php/api/';
  constructor(private http: HttpClient) {
    this.loadToken();
   }
   ngOnInit() {
  console.log("service ready ")
   }


   async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });    
    if (token && token.value) {
      console.log('set token: ', token.value);
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login1(credentials: {email, password}): Observable<any> {
    return this.http.post(`https://reqres.in/api/login`, credentials).pipe(
      map((data: any) => data.data),
      switchMap(data => {
        return from(Storage.set({key: TOKEN_KEY, value: data}));
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    )
  }


  login(email, password): Observable<any> {
    return this.http.get(this.myGlobalUrl + 'ok?email=' + email + '&password=' + password ).pipe(
      map((data: any) => data),
      switchMap(data => {
        return from(Storage.set({key: TOKEN_KEY, value: JSON.stringify(data)}));
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    )
  }


  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    return Storage.remove({key: TOKEN_KEY});
  }


  
  geExam(title: string): Observable<any> {

    console.log(`${this.myGlobalUrl}result?student_id=${this.profile['student_id']}&exam_id=${this.examId}`);
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.myGlobalUrl}result?student_id=${this.profile['student_id']}&exam_id=${this.examId}`).pipe(map(results => results['Search']));
  }
  
  
  getData(title: string): Observable<any> {
    console.log(`${this.myGlobalUrl}${encodeURI(title)}?apikey=${this.profile['student_id']}`);
  
   
  
    return this.http.get(`${this.myGlobalUrl}${encodeURI(title)}?apikey=${this.profile['student_id']}`).pipe(map(results => results['Search']));
  }
  

  settken(title: string): Observable<any> {
    console.log(`${this.myGlobalUrl}/token?token=${encodeURI(title)}?apikey=${this.profile['student_id']}`);
  
   
  
    return this.http.get(`${this.myGlobalUrl}/token?token=${encodeURI(title)}&apikey=${this.profile['student_id']}`).pipe(map(results => results['Search']));
  }
  
  getRoten(): Observable<any> {
  
    console.log(`${this.myGlobalUrl}/roten?school_id=${this.profile['FlatNo']}&standard_id=${this.profile['FlatNo']}`);
  
   // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.myGlobalUrl}roten?school_id=${this.profile['FlatNo']}&standard_id=${this.profile['ClassNo']}`).pipe(map(results => results['Search']));
  }
  
  getTeacher(): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.myGlobalUrl}teacher?school_id=${this.profile['FlatNo']}&standard_id=${this.profile['ClassNo']}`).pipe(map(results => results['Search']));
  }
  
  
  getMsg(title, msg): Observable<any> {
  
    console.log(`${this.myGlobalUrl}msg?title=${encodeURI(title)}&msg=${encodeURI(msg)}&student_id=${this.profile['student_id']}`);
  
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.myGlobalUrl}msg?title=${encodeURI(title)}&msg=${encodeURI(msg)}&student_id=${this.profile['student_id']}`).pipe(map(results => results['Search']));
  
  }
}
